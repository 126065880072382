'use strict';

module.exports = require('base/product/quickView');

module.exports.availability = require('./base').availability;

module.exports.onQuickviewReady = function () {
    var customCarousel = require('../customCarousel');

    $('body').on('quickview:ready', () => {
        $('#quickViewModal').on('shown.bs.modal', () => {
            // check if all images are loaded before initializing the carousel.
            const sliders = $('.product-quickview .glider');
            sliders.each(function () {
                const slider = this;
                let imgs = this.querySelectorAll('img');
                let counter = 0;

                [].forEach.call(imgs, function (img) {
                    if (img.complete) {
                        counter++;
                        if (counter === imgs.length) {
                            customCarousel.create(slider);
                        }
                    } else {
                        img.addEventListener('load', function () {
                            counter++;
                            if (counter === imgs.length) {
                                customCarousel.create(slider);
                            }
                        }, false);
                    }
                });
            });
            $('#quickViewModal').addClass('shown');
        });
    });
};


/**
 * Generates the modal window on the first call.
 * SFRA FUNCTION
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * SFRA FUNCTION
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * ADJUSTED SFRA FUNCTION - created more specific modal selectors. Bugfix: It overwrote every model on the current page
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            $('#quickViewModal .full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            require('../components/customSelect').init();
            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:ready');

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * SFRA FUNCTION
 */
module.exports.showQuickview = function () {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        getModalHtmlElement();
        fillModalElement(selectedValueUrl);
    });
};
