'use strict';

var FlyOut = {};
const loginContainer = $('.header-flyout-login');
const loginFlyout = '.header-flyout-content';
const desktopFlyout = '.header-flyout .header-flyout-content';
const mobileFlyout = '.mobile-flyout .header-flyout-content';
var win = $(window);
const responsive = require('../util/responsive');
const overlay = require('../util/overlay');
const closeFlyouts = require('../util/closeFlyouts');
const $header = $('header');
const formValidation = require('./formValidation');
var Recaptcha = require('../util/recaptcha');

/**
 * Initializes the FlyOut component
 */
FlyOut.init = function () {
    FlyOut.login();
    FlyOut.onCloseClick();
    FlyOut.onClick();
    FlyOut.onOrientationChange();
    FlyOut.openOnInit();
};

/**
 * Open the login flyout on init when url has login parameter that is set to true
 */
FlyOut.openOnInit = function () {
    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('login') === 'true') {
        FlyOut.openFlyout();
    }
};

/**
 * Opens the login flyout so it renders for both mobile & desktop
 * @param {any} $event - click event if needed
 */
FlyOut.openFlyout = function ($event) {
    const isMobile = responsive.isMobile();
    if (isMobile && $(mobileFlyout).length === 0) {
        $(desktopFlyout).clone().appendTo('.mobile-flyout');
    }

    $(desktopFlyout).toggleClass('d-none', isMobile);
    $(mobileFlyout).toggleClass('d-none', !isMobile);

    if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    FlyOut.makeFlyoutVisible();
};

/**
 * Shows the login flyout
 */
FlyOut.makeFlyoutVisible = function () {
    // Close all other flyouts
    closeFlyouts.closeSearchSuggestions();

    $header.addClass('flyout-open');
    $(loginFlyout).slideDown('fast');
    loginContainer.addClass('open');
    overlay.insertBefore($(loginFlyout));
    FlyOut.onOverlayClick();
    $('.modal-background-page').show();
};

/**
 * Hides the login flyout
 */
FlyOut.hideFlyout = function () {
    $(loginFlyout).slideUp('fast');
    $header.removeClass('flyout-open');

    loginContainer.removeClass('open');
    overlay.remove();
    $('.modal-background-page').hide();
};

/**
 * The click listener for the login link
 */
FlyOut.onClick = function () {
    loginContainer.click(function ($event) {
        FlyOut.openFlyout($event);
    });
};

FlyOut.onOverlayClick = function () {
    overlay.onClick(FlyOut.hideFlyout);
};

/**
 * Click listener for the flyout close button or esc key press
 */
FlyOut.onCloseClick = function () {
    $(document).on('click', '.header-flyout-content-close', function () {
        FlyOut.hideFlyout();
    });

    $(document).keyup(function (e) {
        if (e.keyCode === 27) FlyOut.hideFlyout();   // esc
    });
};

/**
 * Event listener for the device orientation change
 */
FlyOut.onOrientationChange = function () {
    win.bind('orientationchange', function () {
        FlyOut.hideFlyout();
    });
};

/**
 * Click listener for the flyout login button
 */
FlyOut.login = () => {
    $('form.login [type="submit"]').prop('disabled', false);

    $(document).on('submit', 'form.login', function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');

        form.spinner().start();
        Recaptcha.generateToken('account_login').then(function (token) {
            var dataAction = $('.page').data('action') || 'Home-Show';
            if (dataAction === 'Search-Show' || dataAction === 'Page-Show') {
                dataAction += '?' + $('.page').data('querystring');
            }
            var requestData = form.serialize();
            requestData += '&endpoint=' + dataAction + '&g-recaptcha-response=' + token;

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: requestData,
                success: function (data) {
                    form.spinner().stop();
                    if (data.success && data.redirectUrl) {
                        form.trigger('login:success');
                        location.href = data.redirectUrl;
                    } else if (!data.success && data.redirectUrl) {
                        location.href = data.redirectUrl;
                    } else if (!data.success) {
                        formValidation(form, data);
                        form.trigger('login:error');
                    } else if (form.parents('.header-flyout-content').length) {
                        const $csrfErrorPage = $('.js-csrf-error-page');

                        form.trigger('login:error');

                        if ($csrfErrorPage.length) {
                            location.href = $csrfErrorPage.data('homepage-url');
                        } else {
                            location.reload(true);
                        }
                    }
                },
                error: function (data) {
                    form.trigger('login:error');

                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        form.spinner().stop();
                    }
                }
            });
        });
        return false;
    });
};

module.exports = FlyOut;
