'use strict';

/**
 * gets the querystring value from the url based on a paramter
 * @param {string} name paramater name
 * @returns {string} value of requested parameter
 */
function getParameterByName(name) {
    const url = window.location.href;
    const newName = name.replace(/\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const init = () => {
    var sfmcId = getParameterByName('sfmc_id');
    if (sfmcId !== null && sfmcId !== '') {
        const date = new Date();
        date.setTime(date.getTime() + (60 * 30 * 1000));
        let expires = 'expires=' + date.toUTCString();
        document.cookie = 'sfmc_id=' + sfmcId + ';' + expires + ';path=/';
    }
};

module.exports = {
    init: init
};
