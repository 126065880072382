'use strict';

module.exports = {
    addRecaptchaToForm: function (form, action) {
        grecaptcha.ready(function () {
            var key = $('.js-captchaKey').text();
            grecaptcha.execute(key, { action: action })
                .then(function (token) {
                    $(form).find('input[name="g-recaptcha-response"]').remove();

                    $('<input>').attr({
                        type: 'hidden',
                        name: 'g-recaptcha-response',
                        value: token
                    }).appendTo(form);
                });
        });
    },
    generateToken: function (action) {
        var key = $('.js-captchaKey').text();
        if (!key || key === 'null') {
            return new Promise((resolve) => resolve());
        }
        return grecaptcha.execute(key, { action: action });
    }
};
