window.jQuery = window.$ = require('jquery');

require('core/util/nativeQuery');

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('base/components/menu'));
    processInclude(require('core/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('core/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('core/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('core/components/customSelect'));
    require('core/components/header').init();
    require('core/util/closeFlyouts').init();
    require('core/components/login-flyout').init();
    require('core/components/stickyHeader').init();
    require('core/components/consentTracking').init();
    require('core/product/quantity').init();
    require('core/product/quickview').onQuickviewReady();
    require('core/analytics/events').init();
    require('core/analytics/enhancedEcommerce').init();
    require('core/account/accountMenu').init();
    require('core/components/localePreferences').init();
    require('core/components/subscription').init();
    require('core/components/optin').init();
    require('core/customCarousel').init();
    require('core/evoucher/notification').init();
    require('core/components/CaptureSFMCID').init();
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
