/**
 * Fetches the current transparant overlay instance
 * @returns {jQuery} The element
 */
function getInstance() {
    return $('.transparant-overlay');
}

/**
 * Appends an overlay div into the element
 * @param {jQuery} element The element to append the overlay into
 * @param {boolean} ignoreScroll don't disable body scroll
 */
function insertBefore(element) {
    if (!getInstance().length) {
        $('<div class="transparant-overlay"></div>').insertBefore(element);
    }
}

/**
 * Removes the transparant overlay
 */
function remove() {
    getInstance().detach();
}

/**
 * Click listener for the transparant overlay
 * @param {Function} callback The callback function to execute on click
 */
function onClick(callback) {
    getInstance().click(function () {
        callback();
        $('body').focus();
    });
}

module.exports = {
    insertBefore: insertBefore,
    remove: remove,
    getInstance: getInstance,
    onClick: onClick
};
