'use strict';

const Responsive = require('../util/responsive');
const closeFlyouts = require('../util/closeFlyouts');

/**
 * Instead of duplicating the whole menu.js file, we obtain the original handler and make our changes on top of it.
 */
module.exports = function () {
    const $body = $('body');
    const $header = $('header');
    const $dropdown = $('.nav-item.dropdown:not(.disabled) > [data-toggle="dropdown"]');
    const $mainMenu = $('.main-menu');

    if (!$dropdown.length) {
        return;
    }

    const events = $._data($dropdown[0], 'events');
    const baseMouseEnter = events.mouseover.find((event) => event.origType === 'mouseenter').handler;

    const $dropdownParent = $dropdown.parent();
    const parentEvents = $._data($dropdownParent[0], 'events');
    const baseMouseLeave = parentEvents.mouseout.find((event) => event.origType === 'mouseleave').handler;

    const closeDesktopMenu = (dropdown) => {
        if (typeof dropdown === 'undefined') {
            $dropdownParent.filter('.show').each((i, el) => closeDesktopMenu(el));
            return;
        }

        baseMouseLeave.call(dropdown);
        $(dropdown).removeClass('show');
        $(dropdown).children('.dropdown, .dropdown-menu').removeClass('show');

        $('.modal-background-page').hide();
    };

    const closeMobileMenu = () => {
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $mainMenu.attr('aria-hidden', 'true');
        $mainMenu.siblings().attr('aria-hidden', 'false');
        $header.siblings().attr('aria-hidden', 'false');
        $body.removeClass('no-scroll');
    };

    const closeMenu = () => {
        if (Responsive.isMobile()) {
            closeMobileMenu();
        } else {
            closeDesktopMenu();
        }
    };

    const openDesktopMenu = (dropdown) => {
        closeFlyouts.closeSearchSuggestions();
        baseMouseEnter.call(dropdown);
        $('.modal-background-page').show();
    };

    $dropdown.off('mouseenter').on('mouseenter', function () {
        if (!Responsive.isMobile()) {
            // Small delay before showing the menu
            this.parentElement.dataset.timeoutId = setTimeout(() => openDesktopMenu(this), 150).toString();
        }
    });

    $dropdownParent.off('mouseleave').on('mouseleave', function () {
        if (!Responsive.isMobile()) {
            if (this.dataset.timeoutId) {
                clearTimeout(Number(this.dataset.timeoutId));
                this.dataset.timeoutId = null;
            }
            closeDesktopMenu(this);
        }
    });

    $(window).resize(() => {
        if (Responsive.isMobile()) {
            // Close desktop menu after switching to mobile
            closeDesktopMenu();
        }

        // Always close mobile menu when resizing (when tablets go from portrait to landscape)
        closeMobileMenu();
    });

    // For tablets, open the menu with a tap, instead of opening the link
    $dropdown.on('touchstart', function (e) {
        if (!Responsive.isMobile()) {
            e.preventDefault();
            closeDesktopMenu();
            openDesktopMenu(this);
        }
    });

    $('.js-search-burger').click(closeMobileMenu);

    $('.navbar-toggler').click(() => $body.addClass('no-scroll'));
    $('.close-button', $mainMenu).click(() => $body.removeClass('no-scroll'));

    // close menu on esc
    $(document).keyup(function (e) {
        if (e.keyCode === 27) closeMenu();
    });

    // close on touch mobile side menu
    $body.on('click touchend', '.modal-background', function (e) {
        e.preventDefault();
        closeMobileMenu();
    });
};
