'use strict';

const body = document.body;
const scrollUp = 'scroll-up';
const scrollDown = 'scroll-down';
const mobileWidthBreakpoint = 769;
let lastScroll = 0;


/**
 * Adds 'scroll-up' or 'scroll-down' classes to the body depending on scroll direction
 */
function mobileScrollHeader() {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
}

/**
 * Makes header sticky depending on the current scroll position
 */
function toggleStickyHeader() {
    const $header = $('header');
    const currentScroll = window.pageYOffset;
    let stickyBreakpoint = $header.attr('data-sticky-breakpoint');
    stickyBreakpoint = (stickyBreakpoint && Number(stickyBreakpoint)) || ($header.height() + $header.offset().top);
    const isSticky = currentScroll >= stickyBreakpoint;
    if (isSticky) {
        if (!$header.is('.sticky')) {
            $header.attr('data-sticky-breakpoint', stickyBreakpoint);
        }
        $header.addClass('sticky');
    } else {
        $header.removeClass('sticky');
        $header.attr('data-sticky-breakpoint', null);
    }
}

/**
 * init
 */
function init() {
    window.onscroll = function () {
        const isMobile = window.innerWidth < mobileWidthBreakpoint;
        const isMobileMenuOpen = $('.main-menu.in').length;
        if (isMobile && !isMobileMenuOpen) {
            mobileScrollHeader();
        } else if ($('.main-menu').length) {
            toggleStickyHeader();
        }
    };
}

module.exports = {
    init: init
};
