'use strict';

/**
 *
 * @param {jQuery} $form the form element containing invalid fields
 */
function scrollToFirstInvalidElement($form) {
    var $target = $form.find('.invalid-feedback').closest(':visible');
    if ($target && $target.length > 0) {
        $('html, body')
        .animate({
            scrollTop: $target
                .offset().top - $('header').innerHeight()
        }, 500);
    }
}

module.exports = {
    scrollToFirstInvalidElement: scrollToFirstInvalidElement
};
