'use strict';

const customCarousel = require('../customCarousel');
const Wishlist = require('./wishlist');
const EinsteinSlider = {};

/**
 * Observe product slider mutations, required to initialize einstein sliders
 * @param {string} recommender - Make recommender sliders more specific to prevent reinitialisation of an already initialised slider
 */
EinsteinSlider.initMutationObserver = (recommender) => {
    const parentContainers = document.querySelectorAll('div[id^="cq_"], .js-einstein-slider');
    if (!parentContainers.length) return;

    const config = {
        childList: true,
        subtree: true
    };
    for (let i = 0; i < parentContainers.length; i++) {
        const parentContainer = parentContainers[i];
        const callback = (mutationList, observer) => {
            const targetNodes = parentContainer.querySelector(recommender + ' .recommendation-glider');
            if (targetNodes) {
                // initiate slider
                customCarousel.create(targetNodes);

                // initiate product wishlist icons
                Wishlist.initProducts();

                observer.disconnect();
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(parentContainer, config);
    }
};

module.exports = EinsteinSlider;
