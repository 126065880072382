'use strict';

/**
 * Push the breadcrumb clicks to the datalayer.
 */
function pushBreadCrumb() {
    const breadCrumbElements = $$$('.breadcrumb-item');
    const breadCrumbList = {
        event: 'contentInfo'
    };

    if (breadCrumbElements.length > 0) {
        breadCrumbElements.forEach((breadCrumbElement, index) => {
            const breadCrumbValue = breadCrumbElement.textContent.trim();
            breadCrumbList['breadcrumb_level_' + (index + 1)] = breadCrumbValue.toLowerCase();
        });

        dataLayer.push(breadCrumbList);
    }
}

/**
 * Gets the userState. The user can be 'Logged In', 'Recognized' or 'Not Logged In'
 *
 * @returns {string} - User Status
 */
function getUserStatus() {
    const authDataElement = $$('.js-auth-data');
    const authenticated = authDataElement.dataset.authenticated;
    const registered = authDataElement.dataset.registered;

    if (authenticated && registered) {
        return 'Logged in';
    } else if (!authenticated && registered) {
        return 'Recognized';
    }

    return 'Not logged in';
}

/**
 * Gets the userId if available.
 *
 * @returns {string} - User Status
 */
function getUserId() {
    const authDataElement = $$('.js-auth-data');
    return authDataElement.dataset.customerid;
}

/**
 * Push base site information to the data layer.
 */
function pushSiteInformation() {
    const htmlElement = $$('html');
    const pageElement = $$('.page');

    dataLayer.push({
        event: 'siteInfo',
        locale: `${htmlElement.lang}-${htmlElement.dataset.country}`,
        controller: pageElement.dataset.action
    });
}

/**
 * Push event that all javascript has been executed (analytics should always be last in main.js)
 */
function pushJavascriptLoaded() {
    dataLayer.push({
        event: 'javascript',
        value: 'loaded'
    });
}

/**
 * Push event containing the user status
 */
function pushUserInformation() {
    const userStatus = getUserStatus();
    const userId = getUserId();

    dataLayer.push({
        event: 'userStatus',
        value: userStatus
    });

    if (userId && userId !== 'null') {
        dataLayer.push({
            userId: userId
        });
    }
}

/**
 * Initialize event listerens for login to push the form submissions.
 */
function initLoginEventListeners() {
    $('form.login').on('login:success', () => {
        dataLayer.push({
            event: 'loginStatus',
            value: 'Login successful'
        });
    });

    $('form.login').on('login:error', () => {
        dataLayer.push({
            event: 'loginStatus',
            value: 'Login not successful'
        });
    });
}

/**
 * Push cookie information upon accepting / rejecting
 */
function pushCookieInformation() {
    $('body').on('analytics:cookieConsent', function (event, val) {
        dataLayer.push({
            event: 'setCookieConsent',
            value: val
        });
    });
}

/**
 * init
 */
function init() {
    if (typeof dataLayer !== 'undefined') {
        pushSiteInformation();
        pushUserInformation();
        pushCookieInformation();
        pushBreadCrumb();
        pushJavascriptLoaded();

        initLoginEventListeners();
    }
}

module.exports = {
    init: init
};
