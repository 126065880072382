'use strict';

var closeFlyouts = {};

// ------------------------------
// SEARCH SUGGESTIONS
// ------------------------------

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
closeFlyouts.toggleSuggestionsIcon = function (action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
};

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
closeFlyouts.clearSuggestionsModals = function () {
    $('body').removeClass('modal-open');
    $('header').removeClass('flyout-open').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
};

/**
 * Tear down Suggestions panel
 */
closeFlyouts.tearDownSuggestions = function () {
    $('input.search-field').val('');
    closeFlyouts.clearSuggestionsModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
};

/**
 * Close search suggestions modal
 */
closeFlyouts.closeSearchSuggestions = function () {
    if ($('.suggestions').length > 0) {
        $('.suggestions').hide();
        closeFlyouts.toggleSuggestionsIcon('search');
        closeFlyouts.tearDownSuggestions();
    }
};

// ------------------------------
// MINICART
// ------------------------------

/**
 * Close all modals when hover over minicart
 */
closeFlyouts.onHoverMinicart = function () {
    $('.minicart').on('mouseenter', function () {
        closeFlyouts.closeSearchSuggestions();
    });
};

// ------------------------------
// INIT
// ------------------------------

closeFlyouts.init = function () {
    closeFlyouts.onHoverMinicart();
};

module.exports = closeFlyouts;
