const PAGE_GROUP = {
    HOME: 'homepage',
    CATEGORY_LANDING: 'category landing',
    PRODUCT_LISTING: 'product listing',
    PRODUCT_DETAIL: 'product detail',
    SEARCH: 'search',
    WISHLIST: 'wishlist',
    CHECKOUT: 'checkout',
    ACCOUNT: 'account',
    STORE: 'store',
    OTHER: 'other'
};

const checkoutSteps = {
    shipping: 1,
    payment: 2,
    placeOrder: 3
};

let currencyCode;

/**
 * Cleans an object of all undefined properties.
 *
 * @param {Object} obj - The object to clean.
 *
 * @return {Object} - The cleaned object.
 */
function cleanObject(obj) {
    var objectToClean = obj;
    Object.keys(objectToClean).forEach(function (key) {
        if (objectToClean[key] && typeof objectToClean[key] === 'object') {
            cleanObject(objectToClean[key]);
        } else if (obj[key] == null) delete objectToClean[key];

        if (objectToClean && !isNaN(objectToClean.quantity)) {
            objectToClean.quantity = Number(objectToClean.quantity);
        }
    });
    return obj;
}

/**
 * Fetches the current page group.
 *
 * @return {string} - The page group.
 */
function getPageGroup() {
    const pageElement = $$('.page');

    if (pageElement) {
        const action = pageElement.dataset.action;

        switch (action) {
            case 'Home-Show':
                return PAGE_GROUP.HOME;
            case 'Search-Show':
                var queryString = pageElement.dataset.querystring;
                if (queryString.indexOf('cgid') >= 0) {
                    if ($$$('.storepage', pageElement).length > 0) {
                        return PAGE_GROUP.CATEGORY_LANDING;
                    }

                    return PAGE_GROUP.PRODUCT_LISTING;
                }

                return PAGE_GROUP.SEARCH;
            case 'Product-Show':
            case 'Product-ShowInCategory':
                return PAGE_GROUP.PRODUCT_DETAIL;
            case 'ProductLists-Detail':
                return PAGE_GROUP.WISHLIST;
            case 'Cart-Show':
            case 'Checkout-Begin':
            case 'Checkout-Login':
            case 'Order-Confirm':
                return PAGE_GROUP.CHECKOUT;
            case 'Register-Show':
            case 'Login-Show':
            case 'Order-History':
            case 'Order-Details':
            case 'Address-List':
            case 'PaymentInstruments-List':
                return PAGE_GROUP.ACCOUNT;
            case 'Stores-Find':
                return PAGE_GROUP.STORE;
            default:
                break;
        }
    }
    return PAGE_GROUP.OTHER;
}

/**
 * Push event containing the type of page.
 */
function pushPageGroup() {
    dataLayer.push({
        page_group: getPageGroup()
    });
}

/**
 * Push event containing the Category of page.
 */
function pushPageCategory() {
    var $elem = $('.analytics-category');
    if ($elem) {
        var categoryId = $elem.data('categoryid');
        if (categoryId) {
            dataLayer.push({
                pageCategory: categoryId
            });
        }
    }
}

/**
 * Process a single product item.
 * @param {number} index - Current item index
 * @param {HTMLElement} analyticsElement - Current analytics element
 * @param {Object} ecommerceData - Data object
 */
function processSingleProductImpression(index, analyticsElement, ecommerceData) {
    var jsonString = analyticsElement.dataset.content;

    if (jsonString && jsonString.length > 0) {
        var jsonData = JSON.parse(jsonString);
        jsonData.position = index;
        jsonData.list = getPageGroup();

        ecommerceData.ecommerce.impressions.push(cleanObject(jsonData));
    }
}

/**
 * Push product impressions to data layer when viewed.
 * @param {[IntersectionObserverEntry]} entries - Observer entries
 * @param {IntersectionObserver} observer - The observer
 */
function pushObservableProductImpressions(entries, observer) {
    var ecommerceData = {
        event: 'genEcom',
        eventCategory: 'ecommerce',
        eventAction: 'product impression view',
        eventLabel: getPageGroup(),
        eventValue: undefined,
        eventNonInteraction: true,
        ecommerce: {
            currencyCode: currencyCode,
            impressions: []
        }
    };

    entries.forEach(function (entry) {
        if (entry.intersectionRatio <= 0) {
            return;
        }

        const analyticsElement = $$('meta[itemprom=analytics]', entry.target);

        if (analyticsElement) {
            processSingleProductImpression(
                entry.target.dataset.index, analyticsElement, ecommerceData
            );
        }

        observer.unobserve(entry.target);
    });

    if (ecommerceData.ecommerce.impressions.length > 0) {
        dataLayer.push(ecommerceData);
    }
}

/**
 * Add the intersection observer to the product tile DOM elements loaded.
 * When the elements become visible, the intersection observer will execute.
 * @param {NodeList} nodes - the node list containing the product tile elements
 * @param {number} index - The position of the product
 * @param {IntersectionObserver} intersectionObserver - The intersection observer
 */
function initObservableProductImpressions(nodes, index, intersectionObserver) {
    let i = index;

    nodes.forEach((node) => {
        if (node.nodeType === Node.COMMENT_NODE || !node.dataset) {
            return;
        }

        if (!node.dataset.index) {
            node.dataset.index = i;
        }

        intersectionObserver.observe(node);
        i++;
    });
}

/**
 * Initializes the product impressions observer.
 */
function initProductImpressions() {
    if ('IntersectionObserver' in window) {
        try {
            const intersectionObserver = new IntersectionObserver(function (entries) {
                pushObservableProductImpressions(entries, intersectionObserver);
            }, {
                threshold: [0.75]
            });

            const mutationConfig = { childList: true };
            // List all target containers that need to be watched. Every $('.product[data-pid]') will be observed
            const mutationTargets = [
                ...$$$('.product-grid'),
                ...$$$('.carousel')
            ];

            const mutationObserver = new MutationObserver(function (mutations) {
                // Loop the mutations that are triggered defined in mutationTargets
                mutations.forEach(function (mutation) {
                    // When clicking more button, add the current loaded items to the 'position' attribute (specific for lister)
                    const pageSize = $('.grid-footer').data('page-size') || 0;
                    const pageNumber = $('.grid-footer').data('page-number') || 0;
                    const currentLoadedItems = pageSize * pageNumber;

                    // Async page load (more button, filters, sort) - observe loaded product tiles
                    const nodes = mutation.addedNodes;
                    initObservableProductImpressions(nodes, currentLoadedItems + 1, intersectionObserver);
                });
            });

            if (mutationTargets && mutationTargets.length > 0) {
                mutationTargets.forEach(function (mutationTarget) {
                    // Initial page load - observe loaded product tiles
                    const nodes = $$$('.product', mutationTarget);
                    initObservableProductImpressions(nodes, 1, intersectionObserver);

                    // Observe further added product-tiles
                    mutationObserver.observe(mutationTarget, mutationConfig);
                });
            }
        } catch (error) {
            // DO NOTHING
        }
    }
}

/**
 * Listen for product clicks and push the product click event.
 */
function initProductClickListeners() {
    $(document).on('click', '.product[data-pid]', function () {
        const analyticsElement = $$('meta[itemprom=analytics]', this);

        if (analyticsElement) {
            const analyticsString = analyticsElement.dataset.content;

            if (analyticsString) {
                var analyticsObject = JSON.parse(analyticsString);
                analyticsObject.position = $(this).data('index');
                var list = getPageGroup();

                var ecommerceData = {
                    event: 'genEcom',
                    eventCategory: 'ecommerce',
                    eventAction: 'product impression click',
                    eventLabel: getPageGroup(),
                    eventValue: undefined,
                    eventNonInteraction: false,
                    ecommerce: {
                        currencyCode: currencyCode,
                        click: {
                            actionField: {
                                list: list
                            },
                            products: [
                                cleanObject(analyticsObject)
                            ]
                        }
                    }
                };

                dataLayer.push(ecommerceData);
            }
        }
    });
}

/**
 * Initialize the click listeners for the wishlist to push the wishlist events.
 */
function initWishlistListeners() {
    $(document).on('click', '.add-to-wish-list', function (e) {
        e.stopPropagation(); // Stop the product click event from triggering

        if (!this.dataset.added) {
            var wishlistItem = $(this).parents('[data-pid]').find('meta[itemprom=analytics]').data('content');
            var obj = {
                event: 'wishlist',
                product: wishlistItem
            };

            dataLayer.push(obj);
        }

        return true;
    });
}

/**
 * Push event containing information when viewing the product detail.
 */
function pushProductDetail() {
    const productDetailElement = $$('.product-detail');

    if (productDetailElement) {
        var analyticsElement = $$('meta[itemprom=analytics]', productDetailElement);

        if (analyticsElement) {
            var jsonString = analyticsElement.dataset.content;

            var ecommerceData = {
                event: 'genEcom',
                eventCategory: 'ecommerce',
                eventAction: 'product detail view',
                eventLabel: getPageGroup(),
                eventValue: undefined,
                eventNonInteraction: true,
                ecommerce: {
                    currencyCode: currencyCode,
                    detail: {
                        actionField: {
                            list: getPageGroup()
                        },
                        products: [
                            cleanObject(JSON.parse(jsonString))
                        ]
                    }
                }
            };

            dataLayer.push(ecommerceData);
        }
    }
}

/**
 * Push added items from basket to datalayer
 * @param {*} productElement - given productElement
 * @param {*} quantity - quantity to add
 */
function pushAddToBasket(productElement, quantity) {
    if (productElement) {
        var analyticsElement = $$('meta[itemprom=analytics]', productElement);

        if (analyticsElement) {
            var quantityChange = Number(quantity) || $$('.quantity-select', productElement).value;
            var jsonObject = JSON.parse(analyticsElement.dataset.content);

            jsonObject.quantity = quantityChange || 1;

            var ecommerceData = {
                event: 'genEcom',
                eventCategory: 'ecommerce',
                eventAction: 'add to cart',
                eventLabel: getPageGroup(),
                eventValue: undefined,
                eventNonInteraction: false,
                ecommerce: {
                    currencyCode: currencyCode,
                    add: {
                        actionField: {
                            list: getPageGroup()
                        },
                        products: [
                            cleanObject(jsonObject)
                        ]
                    }
                }
            };
            dataLayer.push(ecommerceData);
        }
    }
}

/**
 * Push removed items from basket to datalayer
 * @param {Object} $lineItemContainer - current lineItemContainer
 * @param {*} quantity - given quantity
 */
function pushRemoveFromBasket($lineItemContainer, quantity) {
    if ($lineItemContainer) {
        var $analyticsElement = $$('meta[itemprom=analytics]', $lineItemContainer);
        if ($analyticsElement) {
            var jsonObject = JSON.parse($analyticsElement.dataset.content);

            var objectQuantity;
            if (quantity) {
                objectQuantity = Number(quantity);
            } else if ($$('.quantity-form .quantity', $lineItemContainer)) {
                objectQuantity = Number($$('.quantity-form .quantity', $lineItemContainer).value);
            } else { // Products without quantity selector. Ex: Evouchers. (single products)
                objectQuantity = 1;
            }

            if (jsonObject) {
                jsonObject.quantity = objectQuantity;
                var ecommerceData = {
                    event: 'genEcom',
                    eventCategory: 'ecommerce',
                    eventAction: 'remove from cart',
                    eventLabel: getPageGroup(),
                    eventValue: undefined,
                    eventNonInteraction: false,
                    ecommerce: {
                        currencyCode: currencyCode,
                        remove: {
                            actionField: {
                                list: getPageGroup()
                            },
                            products: [
                                cleanObject(jsonObject)
                            ]
                        }
                    }
                };
                dataLayer.push(ecommerceData);
            }
        }
    }
}

/**
 * Initialize the cart listeners to push events about the cart.
 */
function initCartListeners() {
    $('body').on('minicart:pageload', () => {
        var analyticsElements = $('.minicart .popover meta[itemprom=analytics]');
        if (analyticsElements && analyticsElements.length) {
            var cart = [];
            analyticsElements.each(function () {
                var jsonObject = $(this).data('content');
                jsonObject.quantity = $(this).parent().find('.input-quantity').val();

                var object = {
                    item: jsonObject.id,
                    quantity: jsonObject.quantity,
                    price: jsonObject.price,
                    unique_id: ''
                };

                cart.push(object);
            });

            var ecommerceData = {
                event: 'cartContent',
                basket: cart
            };
            dataLayer.push(ecommerceData);
        }
    });

    $(document).on('product:afterAddToCart', () => {
        var productDetailElement = $$('.product-detail');
        pushAddToBasket(productDetailElement);
    });

    $(document).on('updateAddToCartFormData', (event, data) => {
        var pid = data.pid;
        var productListElement = $$(`.product[data-pid='${pid}']`);
        pushAddToBasket(productListElement, data.quantity);
    });

    $(document).on('cart:emptyCardAfterRemove', () => {
        var ecommerceData = {
            event: 'clearCart'
        };

        dataLayer.push(ecommerceData);
    });

    $(document).on('cart:afterRemoveFromCart', (event, uuid) => {
        var $lineItemContainer = $$(`.uuid-${uuid}`);
        pushRemoveFromBasket($lineItemContainer);
    });

    $(document).on('change', '.input-quantity-cart', function () {
        var previousAmount = Number($(this).data('pre-select-qty'));
        var change = Number($(this).val()) - previousAmount;

        var $productLineitem = this.closest('.product-info');
        if (change < 0) {
            pushRemoveFromBasket($productLineitem, Math.abs(change));
        } else {
            pushAddToBasket($productLineitem, change);
        }
        $(this).data('pre-select-qty', $(this).val());
    });
}

/**
 * A function to handle a click leading to a checkout option selection.
 * @param {number} step - The checkout step
 */
function onCheckoutStepChange(step) {
    var ecommerceData = {
        event: 'genEcom',
        eventCategory: 'ecommerce',
        eventAction: 'checkout',
        eventLabel: 'step ' + step,
        eventValue: undefined,
        eventNonInteraction: true,
        ecommerce: {
            currencyCode: currencyCode,
            checkout: {
                actionField: {
                    step: step
                },
                products: []
            }
        }
    };

    $('.product[data-pid] meta[itemprom=analytics], .product-info meta[itemprom=analytics]').each(function (index, trackingItem) {
        var $trackingItem = $(trackingItem);
        var jsonString = $trackingItem.data('content');

        if (jsonString) {
            ecommerceData.ecommerce.checkout.products.push(
                cleanObject(jsonString)
            );
        }
    });

    dataLayer.push(ecommerceData);
}

/**
 * Initiate checkout steps datalayer
 */
function initCheckoutSteps() {
    $(document).on('checkout:loadStage', function (e, currentStageName) {
        var currentStep = checkoutSteps[currentStageName];
        onCheckoutStepChange(currentStep);
    });
}

/**
 * Initiate checkout confirmation page datalayer
 */
function initConfirmCheckout() {
    var $page = $('.page');
    if ($page.data('action') === 'Order-Confirm') {
        var analyticsElement = document.getElementById('purchaseEvent');
        var jsonString = analyticsElement.getAttribute('content');
        if (jsonString && jsonString.length > 0) {
            var jsonObject;
            try {
                jsonObject = JSON.parse(jsonString);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(`Could not parse string to JSON: ${jsonString}`);
            }
            jsonObject.products.forEach(function (product) {
                product.id = product.sku;
            });
            if (jsonObject) {
                var ecommerceData = {
                    event: 'genEcom',
                    eventCategory: 'ecommerce',
                    eventAction: 'purchase',
                    eventLabel: jsonObject.affiliation,
                    eventValue: undefined,
                    eventNonInteraction: true,
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: jsonObject.id,
                                revenue: jsonObject.revenue,
                                affiliation: jsonObject.affiliation,
                                tax: jsonObject.tax,
                                shipping: jsonObject.shipping,
                                coupon: jsonObject.couponCode
                            },
                            products: jsonObject.products
                        }

                    }
                };
                dataLayer.push(cleanObject(ecommerceData));

                // ABANDONED CART
                var basket = [];
                jsonObject.products.forEach(function (product) {
                    var object = {
                        item: product.sku,
                        quantity: product.quantity,
                        price: product.price,
                        unique_id: product.id
                    };
                    basket.push(object);
                });
                var purchaseContent = {
                    event: 'purchaseContent',
                    basket: basket
                };
                dataLayer.push(cleanObject(purchaseContent));

                // TRUSTED SHOPS
                var trustedShopsOrderObject = {
                    tsCheckoutOrderNr: jsonObject.id,
                    tsCheckoutOrderAmount: jsonObject.revenue,
                    tsCheckoutBuyerEmail: jsonObject.customerEmail,
                    tsCheckoutOrderPaymentType: jsonObject.paymentType,
                    tsCheckoutOrderCurrency: jsonObject.currencyCode
                };
                dataLayer.push(trustedShopsOrderObject);

                jsonObject.products.forEach(function (product) {
                    var trustedShopsProductObject = {
                        tsCheckoutProductUrl: product.url,
                        tsCheckoutProductImageUrl: product.imgUrl || '',
                        tsCheckoutProductName: product.name,
                        tsCheckoutProductSKU: product.sku,
                        tsCheckoutProductID: product.sku,
                        tsCheckoutProductBrand: product.brand || ''
                    };
                    dataLayer.push(trustedShopsProductObject);
                });
            }
        }
    }
}

/**
 *
 * @param {integer} checkoutStep - Position in checkout
 * @param {string} option - Item to show as option
 */
function handleCheckoutOption(checkoutStep, option) {
    var ecommerceData = {
        event: 'genEcom',
        eventCategory: 'ecommerce',
        eventAction: 'checkout option',
        eventLabel: 'step ' + checkoutStep,
        eventValue: undefined,
        eventNonInteraction: false,
        ecommerce: {
            currencyCode: currencyCode,
            checkout_option: {
                actionField: {
                    step: checkoutStep,
                    option: option
                }
            }
        }
    };

    dataLayer.push(ecommerceData);
}

/**
 * Initialize checkout listeners
 */
function initCheckoutListener() {
    // pass selected shipping method
    $('button.submit-shipping').on('click', function () {
        var selectedShippingMethod = $$('.shipping-method-list input[type=radio][name=dwfrm_shipping_shippingAddress_shippingMethodID]:checked');
        var value = selectedShippingMethod.value.toLowerCase().replace(/[-_]/g, ' ');
        handleCheckoutOption(checkoutSteps.shipping, value);
    });

    // // Pass giftmessage - Temporarily disabled
    // $(document).on('change', '.gift-message-block input.gift', function () {
    //     var option;
    //     if ($(this).prop('checked')) {
    //         option = 'add gift message';
    //     } else {
    //         option = 'no gift message';
    //     }

    //     handleCheckoutOption(checkoutSteps.shipping, option);
    // });
}

/**
 * init
 */
function init() {
    currencyCode = $('html').data('currency');

    if (typeof dataLayer !== 'undefined') {
        pushPageGroup();
        pushPageCategory();
        pushProductDetail();

        initProductClickListeners();
        initWishlistListeners();
        initCartListeners();
        initProductImpressions();
        initCheckoutSteps();
        initConfirmCheckout();
        initCheckoutListener();
    }
}

module.exports = {
    init: init
};
