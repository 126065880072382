'use strict';

/**
 * Shows banner and hides all the others
 * @param {HTMLElement} $banner - Banner that should be shown
 */
function showBanner($banner) {
    $banner.siblings().addClass('hide');
    $banner.removeClass('hide');
}

/**
 * init
 */
function init() {
    var $banners = $('.js-promotion-banners').children();
    if (!$banners.length) {
        return;
    }
    showBanner($banners.eq(0));
    var visibleIndex = 0;
    setInterval(function () {
        visibleIndex = (visibleIndex + 1) % $banners.length;
        showBanner($banners.eq(visibleIndex));
    }, 5000);
}

module.exports = {
    init: init
};
