'use strict';

const cookie = require('js-cookie');
const cookieOptions = {
    secure: true,
    path: '/',
    expires: 18250
};

var cookieBanner = $('.js-consent-tracking');
var adaptCookiesBanner = $('.js-adapt-cookies-banner');
var urlAccept = cookieBanner.data('accept');
var urlReject = cookieBanner.data('reject');

/**
 * Show a sticky banner at the bottom of the page for consenting with the site tracking policy
 * @param {boolean} show depending if the banner should be hidden or not
 * @param {any} bannerComponent banner component that should be hidden or not
 */
function showConsentBanner(show, bannerComponent) {
    bannerComponent.toggleClass('d-none', !show);
    $('body').toggleClass('modal-open', show); // disable scroll on body
    if (show) {
        $('.modal-background-consent').show();
    } else {
        $('.modal-background-consent').hide();
    }
}

/**
 * Handles consent tracking button clicked.
 */
function handleAgreeButtonClicked() {
    $('.js-consent-tracking .js-consent-tracking-true, .js-adapt-cookies-banner .js-consent-tracking-true').click(function (e) {
        e.preventDefault();
        $.ajax({
            url: urlAccept,
            type: 'get',
            dataType: 'json',
            success: function () {
                showConsentBanner(false, cookieBanner);
                showConsentBanner(false, adaptCookiesBanner);

                var cookieValue = 'functional1-performance1-advertising1';
                cookie.set('cookieConsent', cookieValue, cookieOptions);
                cookie.set('consentTrackingVerified', true, cookieOptions);
                $('body').trigger('analytics:cookieConsent', cookieValue);
                $('#preferenceModal').modal({ backdrop: 'static', keyboard: false });
                $('#preferenceModal').modal('show');
            },
            error: function () {
                showConsentBanner(true, cookieBanner);
            }
        });
    });
}

/**
 * Sends the cookie adapt preferences call when accepted or rejected
 * @param {string} ajaxUrl the URL the ajax call will be made to
 */
function sendAdaptCookiesAjaxCall(ajaxUrl) {
    $.ajax({
        url: ajaxUrl,
        type: 'get',
        dataType: 'json',
        success: function () {
            showConsentBanner(false, cookieBanner);
            showConsentBanner(false, adaptCookiesBanner);

            var cookieValue = '';

            if ($('#functionalCookies').is(':checked') || $('#PerformanceCookies').is(':checked') || $('#AdvertisingCookies').is(':checked')) {
                cookie.set('consentTrackingVerified', true, cookieOptions);
            }

            cookieValue += $('#functionalCookies').is(':checked') ? 'functional1' : 'functional0';
            cookieValue += $('#PerformanceCookies').is(':checked') ? '-performance1' : '-performance0';
            cookieValue += $('#AdvertisingCookies').is(':checked') ? '-advertising1' : '-advertising0';

            cookie.set('cookieConsent', cookieValue, cookieOptions);
            $('body').trigger('analytics:cookieConsent', cookieValue);
        },
        error: function () {
            showConsentBanner(true, adaptCookiesBanner);
        }
    });
}

/**
 * Handles save cookie preferences clicked.
 */
function handleSaveMyPreferencesButtonClicked() {
    $('.js-adapt-cookies-banner .js-save-preferences').click(function (e) {
        e.preventDefault();

        var cookieUrl = ($('#functionalCookies').is(':checked') || $('#PerformanceCookies').is(':checked') || $('#AdvertisingCookies').is(':checked')) ? urlAccept : urlReject;
        sendAdaptCookiesAjaxCall(cookieUrl);
    });
}

/**
 * init
 */
function init() {
    if ($('.consented').length === 0 && cookieBanner.hasClass('api-true')) {
        var accepted = cookie.get('consentTrackingVerified') === 'true';
        if (accepted) {
            $.ajax({
                url: urlAccept,
                type: 'get',
                dataType: 'json'
            });
        } else {
            showConsentBanner(true, cookieBanner);
            handleAgreeButtonClicked();
            handleSaveMyPreferencesButtonClicked();
        }

        $('.js-consent-tracking .js-adapt-cookies').click(function (e) {
            e.preventDefault();
            showConsentBanner(false, cookieBanner);
            showConsentBanner(true, adaptCookiesBanner);
        });

        $('.adaptcookies-container .js-adaptcookies-close').click(function (e) {
            e.preventDefault();
            showConsentBanner(false, adaptCookiesBanner);
            showConsentBanner(true, cookieBanner);
        });
    }
}

module.exports = {
    init: init
};
