const responsive = require('../util/responsive');

const handleToggleNavBar = (e) => {
    const currentTarget = e.currentTarget;
    if (responsive.isTabletOrSmaller()) {
        currentTarget.classList.toggle('active');
    }
};

const init = () => {
    const listElement = document.querySelector('ul.sidebar');
    if (listElement) {
        listElement.addEventListener('click', handleToggleNavBar);
    }
};

module.exports = {
    init: init
};
