'use strict';

/**
 * Updates product icon depending on if product was added to the wishlist or removed.
 * @param {boolean} adding - True if we were adding a product to the wishlist. False if we were removing one.
 * @param {Object} $button - button that was clicked to add/remove a product to the wishlist
 */
function updateProductIcon(adding, $button) {
    if (adding) {
        $button.find('.icon')
            .removeClass('icon-wishlist-add')
            .addClass('icon-wishlist-added')
            .parent()
            .attr('title', function () {
                return $(this).data('remove-title');
            })
            .attr('data-added', 'true');
    } else {
        $button.find('.icon')
            .removeClass('icon-wishlist-added')
            .addClass('icon-wishlist-add')
            .parent()
            .attr('title', function () {
                return $(this).data('add-title');
            })
            .removeAttr('data-added');
    }
}

/**
 * Updates header's icon count and changes its icon depending on whether if it has any product or not
 * @param {Object} data - Response
 */
function updateHeaderIcon(data) {
    if (data && data.products) {
        const $wishlist = $('.wishlist-count');
        $wishlist.attr('data-products', data.products);
        $wishlist.text(data.count);
        if (data.count > 0) {
            $wishlist.prev()
                .removeClass('icon-wishlist')
                .addClass('icon-wishlist-active');
        } else {
            $wishlist.prev()
                .removeClass('icon-wishlist-active')
                .addClass('icon-wishlist');
        }
    }
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.add-to-wish-list', function (e) {
            e.preventDefault();
            const adding = !$(this).attr('data-added');
            const url = adding ? $(this).attr('data-add-url') : $(this).attr('data-remove-url');
            const pid = $(this).closest('[data-pid]').data('pid');
            let optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            let optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: adding ? 'post' : 'get',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                }
            })
                .done(data => {
                    updateProductIcon(adding, $(this));
                    updateHeaderIcon(data);
                })
                .always(() => $.spinner().stop());
        });
    },
    initProducts: function () {
        /**
         * Sets wishlist icon active for products already added to the wishlist
         */
        function updateProductsWishlistIcons() {
            const products = JSON.parse($('.wishlist-count').attr('data-products'));
            products.forEach(productSku => {
                var productIcons = $(`[data-pid="${productSku}"]`).find('.add-to-wish-list:first');
                updateProductIcon(true, productIcons);
            });
            $('.add-to-wish-list').removeClass('d-none');
        }

        updateProductsWishlistIcons();

        $(document).on('search:showMore', updateProductsWishlistIcons);
        $(document).on('search:filter', updateProductsWishlistIcons);
    },
    updateHeaderIcon: updateHeaderIcon
};
