'use strict';
const Glider = require('./util/thirdParty/glider');

module.exports.init = () => {
    this.createAll('.glider');
};

module.exports.createAll = (selector) => {
    $(selector).each((i, element) => {
        this.create(element);
    });
};

module.exports.create = (element) => {
    // Parent that holds the config attributes
    const parent = $(element).closest('.carousel');

    // get amount of slides for each breakpoint
    const amountSlidesXs = parent.data('xs') || parent.data('slides');
    const amountSlidesSm = parent.data('sm');
    const amountSlidesMd = parent.data('md');

    // other options
    let draggable = parent.data('draggable');
    let dots = parent.data('dots');
    let scrollLock = parent.data('scroll-lock');
    let scrollDelay = parent.data('scroll-delay');
    let rewind = parent.data('rewind');

    if (draggable === undefined) {
        draggable = true;
    }

    // get the arrow buttons form the slider
    const $arrowPrev = parent.find('.carousel-control-prev');
    const $arrowNext = parent.find('.carousel-control-next');

    var object = {
        slidesToShow: amountSlidesXs,
        slidesToScroll: amountSlidesXs,
        draggable: draggable,
        arrows: {
            prev: $arrowPrev[0],
            next: $arrowNext[0]
        },
        responsive: []
    };

    if (amountSlidesSm) {
        object.responsive.push({
            breakpoint: 575,
            settings: {
                slidesToShow: amountSlidesSm,
                slidesToScroll: amountSlidesSm
            }
        });
    }

    if (amountSlidesMd) {
        object.responsive.push({
            breakpoint: 768,
            settings: {
                slidesToShow: amountSlidesMd,
                slidesToScroll: amountSlidesMd
            }
        });
    }

    if (dots !== undefined) {
        object.dots = dots;
    }

    if (scrollLock) {
        object.scrollLock = true;
    }

    if (rewind) {
        object.rewind = true;
    }

    $(element).on('glider-loaded glider-refresh scroll', () => {
        // Hide arrows if they are not needed
        const arrowsNotNeeded = element.scrollWidth <= element.offsetWidth;
        $arrowPrev.toggleClass('d-none', arrowsNotNeeded || element.scrollLeft === 0);
        $arrowNext.toggleClass('d-none', arrowsNotNeeded || element.scrollLeft >= (element.scrollWidth - element.offsetWidth));
    });

    var carousel = new Glider(element, object);

    if (scrollDelay) {
        let autoplayDelay = parseInt(scrollDelay, 10) * 1000;

        setInterval(() => {
            carousel.scrollItem('next');
        }, autoplayDelay);
    }

    return carousel;
};

module.exports.scrollItem = (gliderElement, item) => {
    // This won't try to re-build the entire slider, if it's already a Glider slider, "new Glider()" will just return the exposed Glider object on the DOM element
    new Glider(gliderElement).scrollItem(item);
};
