'use strict';

var optin = {};

/**
 * Start triggers on init when url has optin parameter 'true'
 */
optin.init = function () {
    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('optin') === 'true') {
        optin.handleOptinTriggers(searchParams.get('mail'), searchParams.get('enc'));
    }
};

/**
 * Ajax call to controller that handles trigger
 * @param {string} mail - customer email
 * @param {string} enc - encrypted customer email
 */
optin.handleOptinTriggers = function (mail, enc) {
    var $modal = $('#optinModal');
    var url = $modal.data('url'); // Home-Optin

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            mail: mail,
            enc: enc
        },
        success: function (response) {
            if (response.error) {
                $modal.find('.js-optin-icon').addClass('d-none');
                $modal.find('.js-optin-subtitle').addClass('d-none');
                $modal.find('.js-optin-description').text(response.msg);
                var $title = $modal.find('.js-optin-title');
                $title.text($title.data('error'));
            }

            $modal.modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

module.exports = optin;
