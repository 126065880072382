'use strict';

const init = () => {
    $('.js-online-giftcard, .js-boutique-giftcard').on('click', function (e) {
        e.preventDefault();
        const url = $('.evoucherModal-container').data('notify-url'); // Evoucher-Notify
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: {
                type: $(this).hasClass('js-online-giftcard')
            },
            success: function (data) {
                // console.log('data', data);
                $.spinner().stop();
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    // add modal to dom
                    $('.evoucherModal-container').html('').append(data);

                    // show modal
                    const $modal = $('.evoucherNotifyModal');
                    $modal.modal('show');
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

module.exports = {
    init: init
};
