'use strict';
const validationHelper = require('../util/validation');

module.exports = function (formElement, payload) {
    // clear form validation first
    $('.form-group.is-invalid', formElement).removeClass('is-invalid');
    $('.form-feedback', formElement).remove();

    if (!payload) {
        return true;
    }

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach(function (key) {
            if (payload.fields[key]) {
                var formGroup = $(formElement).find('[name="' + key + '"]')
                    .parents('.form-group');
                var feedbackElement = formGroup
                    .children('.invalid-feedback');

                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        feedbackElement.html(payload.fields[key]);
                    }
                    formGroup.addClass('is-invalid');
                }
            }
        });
        validationHelper.scrollToFirstInvalidElement($(formElement));
    }

    if (payload && payload.error) {
        var form = $(formElement).closest('form');

        form.prepend('<div class="form-feedback d-block my-2">'
        + payload.error.join('<br/>') + '</div>');
    }

    if (payload.fields && payload.fields.length > 0) {
        return false;
    }

    return true;
};
