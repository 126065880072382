'use strict';

const languageAjax = (url, countryCode, languageCode, currencyCode) => {
    var localeCode = languageCode.toLowerCase() + '_' + countryCode;
    var queryString = $('.page').data('querystring');
    var action = $('.page').data('action');
    var localeCurrencyCode = currencyCode || $('html').data('currency');
    var pageDesignerPageID = $('.storepage') ? $('.storepage').attr('id') : null;
    var pageDesignerCategoryPage = $('.storepage') ? $('.storepage').data('cgid') : null;

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            code: localeCode,
            queryString: queryString,
            CurrencyCode: localeCurrencyCode,
            action: action,
            cid: pageDesignerPageID,
            cgid: pageDesignerCategoryPage
        },
        success: function (response) {
            $.spinner().stop();
            if (response && response.redirectUrl) {
                window.localStorage.setItem('preferredLocale', localeCode);
                window.location.href = response.redirectUrl;
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

const languageSwitch = () => {
    $('.language-selector').on('change', function () {
        var url = $(this).data('url');
        var countryCode;
        if ($(this).parents('.modal').length) {
            $('.modal-country > div').each(function () {
                if (!$(this).hasClass('d-none')) {
                    var countrySelect = $(this).find('option:selected', '.modal-country');
                    countryCode = countrySelect.val();
                }
            });
        } else {
            countryCode = $(this).data('country');
        }
        var languageCode = $('option:selected', this)[0].value;

        languageAjax(url, countryCode, languageCode);
    });

    $('.modal-confirm-preference').on('click', function () {
        var url = $(this).data('url');

        var countryValue;
        var countrySelect;
        $('.modal-country > div').each(function () {
            if (!$(this).hasClass('d-none')) {
                countrySelect = $(this).find('option:selected', '.modal-country');
                countryValue = countrySelect.val();
            }
        });

        var languageValue;
        var languageSelect;
        $('.modal-language .modal-language-select > div').each(function () {
            if (!$(this).hasClass('d-none')) {
                languageSelect = $(this).find('option:selected', '.modal-language');
                languageValue = languageSelect.val();
            }
        });

        if (countrySelect.hasClass('js-ext')) {
            window.location.href = countryValue;
        } else {
            languageAjax(url, countryValue, languageValue);
        }
    });
};

const currencySwitch = () => {
    $('.currency-selector').on('change', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var currencyCode = $('option:selected', this)[0].value || $('.page').data('currency');
        var countryCode = $('html').data('country');
        var languageCode = $('html').attr('lang');

        languageAjax(url, countryCode, languageCode, currencyCode);
    });
};

const preferredLocale = () => {
    var preferredLocale = window.localStorage.getItem('preferredLocale');
    const siteLocale = $('[data-locale]').data('locale');
    const url = $('.utilURLs').data('request-locale-url'); // Page-RequestDefaultLocale
    if (!preferredLocale && url) {
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            success: function (response) {
                $.spinner().stop();
                if (!$('.utilURLs').data('is-pagedesigner')) {
                    window.localStorage.setItem('preferredLocale', response.locale);

                    $('.js-reqdefault').removeClass('d-none');
                    $('.js-locale').addClass('d-none');
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    } else if (preferredLocale !== siteLocale && url) {
        var loc = preferredLocale.split('_');
        languageAjax(url, loc[1], loc[0]);
    }
};

const handleCountrySwitch = (elem) => {
    $.spinner().start();
    var url = $(elem).data('url');
    var countryCode = $('option:selected', elem)[0].value;

    $('.modal .selector-country').val(countryCode).selectric('refresh');
    $('footer .language-selector').data('country', countryCode);
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            countryCode: countryCode
        },
        success: function (response) {
            $('.modal-language-select').html(response.renderedTemplate);
            $('footer .language-selector').html(response.renderedTemplate);
            require('./customSelect').init();
            $.spinner().stop();
            if ($('footer').has(elem).length) {
                $('footer .language-selector').trigger('change');
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

const handleAlphabeticalSort = ($select) => {
    // sort countries alphabetical
    $select.html($select.find('option').sort(function (x, y) {
        return $(x).text() > $(y).text() ? 1 : -1;
    }));
    // move external sites to the bottom
    $select.find('.js-ext').appendTo($select);

    $select.selectric('refresh');
};

const countrySwitch = () => {
    handleAlphabeticalSort($('footer .selector-country'));
    handleAlphabeticalSort($('.modal-country .js-locale .selector-country'));
    handleAlphabeticalSort($('.modal-country .js-reqdefault .selector-country'));

    $('footer .selector-country').on('change', function (e) {
        e.preventDefault();
        var extCountry = $('option:selected', this)[0].classList.contains('js-ext');
        if (extCountry) {
            window.location.href = $('option:selected', this)[0].value;
        } else {
            handleCountrySwitch(this);
        }
    });
    $('.modal-country .selector-country').on('change', function (e) {
        e.preventDefault();
        var extCountry = $('option:selected', this)[0].classList.contains('js-ext');
        if (extCountry) {
            window.location.href = $('option:selected', this)[0].value;
        } else {
            handleCountrySwitch(this);
        }
    });
};


const init = () => {
    languageSwitch();
    preferredLocale();
    countrySwitch();
    currencySwitch();
};

module.exports = {
    init: init,
    handleAlphabeticalSort: handleAlphabeticalSort
};
