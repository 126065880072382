'use strict';

const handleFooterSubscribe = () => {
    $('.js-email-subscribe-form').on('submit', function (e) {
        e.preventDefault();

        let $errorMsg = $(this).find('.invalid-feedback');
        $errorMsg.text('');
        $errorMsg.removeClass('d-block');

        const url = $(this).find('.js-email-subscribe').data('href'); // EmailSubscribe-Show
        const mail = $(this).find('input[name="hpEmailSignUp"]').val();

        var $modal = $('#optinModal');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                hpEmailSignUp: mail
            },
            success: function (response) {
                $.spinner().stop();

                if (response.error) {
                    if (response.technical) {
                        // error service cloud / marketing cloud
                        $modal.find('.js-optin-icon').addClass('d-none');
                        $modal.find('.js-optin-subtitle').addClass('d-none');
                        $modal.find('.js-optin-description').text(response.msg);
                        var $title = $modal.find('.js-optin-title');
                        $title.text($title.data('error'));
                        $modal.modal('show');
                    } else {
                        // error form input
                        $errorMsg.text(response.msg);
                        $errorMsg.addClass('d-block');
                    }
                } else {
                    // reset modal design for possible second try after failed attempt
                    $modal.find('.js-optin-icon').removeClass('d-none');
                    $modal.find('.js-optin-subtitle').removeClass('d-none');
                    var $descSuccess = $modal.find('.js-optin-description');
                    $descSuccess.text($descSuccess.data('succes'));
                    var $titleSuccess = $modal.find('.js-optin-title');
                    $titleSuccess.text($titleSuccess.data('succes'));

                    if (response.doubleOptin) {
                        $modal.find('.js-optin-description').text(response.msg);
                    }

                    $modal.modal('show');
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

const init = () => {
    handleFooterSubscribe();
};

module.exports = {
    init: init
};
