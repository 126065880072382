'use strict';

require('../thirdParty/jquery.selectric');

const responsive = require('../util/responsive');

const buildCustomSelect = $select => {
    var $invalidFeedbackElement = $select.siblings('.invalid-feedback');

    $select.selectric({
        responsive: true,
        arrowButtonMarkup: '<b class="button"></b>',
        maxHeight: 200,
        optionsItemBuilder: (item) => {
            var labelText = item.text || item.element.prop('label');
            var itemDataSet = item.element[0].dataset;
            if (itemDataSet.selectItem === 'icon' && itemDataSet.selectIcon) {
                return '<i class="' + itemDataSet.selectIcon + ' mr-3"></i>' + labelText;
            } else if (itemDataSet.selectItem === 'image' && itemDataSet.selectImage && itemDataSet.selectImage !== 'null') {
                return '<img src="' + itemDataSet.selectImage + '" class="mr-3 py-2" />' + labelText;
            }

            return labelText;
        },
        labelBuilder: (item) => {
            if (responsive.isMobile() && $select.data('custom-label')) {
                return $select.data('custom-label');
            }

            var labelText = item.text || item.element.prop('label');
            var itemDataSet = item.element[0].dataset;
            if (itemDataSet.selectLabel === 'icon' && itemDataSet.selectIcon) {
                return '<i class="' + itemDataSet.selectIcon + ' mr-3"></i>' + labelText;
            } else if (itemDataSet.selectLabel === 'image' && itemDataSet.selectImage && itemDataSet.selectImage !== 'null') {
                return '<img src="' + itemDataSet.selectImage + '" class="mr-3 py-2" />' + labelText;
            }

            return labelText;
        },
        onInit: (element) => {
            if ($invalidFeedbackElement.length) {
                // We need to create a copy of the feedback element next to the native select,
                // because it's the one that is going to be populated by form validation code
                $(element).after($invalidFeedbackElement.clone());
            }

            $(element)
                .closest('.selectric-wrapper')
                .siblings('.invalid-feedback')
                .slice(1)
                .remove();

            $(element).on('change', function () {
                $(this).selectric('refresh');
            });
        }
    });
};

module.exports = {
    init: () => {
        $('.custom-select').each(function () {
            const $selectric = $(this).data('selectric');
            if ($selectric) {
                $selectric.refresh();
                return;
            }

            buildCustomSelect($(this));

            const mutationObserver = new MutationObserver(mutations => {
                const $target = $(mutations[0].target);
                if (mutations[0].type === 'childList') {
                    $target.selectric('refresh');
                    return;
                }

                // If the change is the class "is-invalid" we mark the selectric component as invalid too
                const isInvalid = $target.is('.is-invalid');
                const $wrapper = $target.closest('.selectric-wrapper');
                $wrapper.toggleClass('selectric-invalid', isInvalid);

                if (isInvalid) {
                    // And copy the message from inner hidden "invalid-feedback" to the outer visible one.
                    $wrapper.siblings('.invalid-feedback').text($target.siblings('.invalid-feedback').text());
                }
            });

            // Listen for option changes (childList) or invalidation (class attribute)
            mutationObserver.observe(this, { childList: true, attributes: true, attributeFilter: ['class'] });
        });
    },

    events: function () {
        $(document).on('refresh:custom-select', () => this.init());
    }
};
